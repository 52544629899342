.one {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ebebeb;
}
.one .canvas-container {
  position: absolute !important;
  top: 10%;
  left: 40%;
  background: #fff;
  border-radius: 8px;
}
.one #imageEffectCanvas {
  border-radius: 8px;
}
.one .left {
  width: 60px;
  height: 100%;
  overflow: hidden;
  background: #f0f3f7;
}
.one .left .logo {
  width: 36px;
  display: block;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.one .left .shell {
  width: 50px;
  margin-bottom: 20px;
  cursor: pointer;
  padding-left: 4px;
}
.one .left .shell img {
  width: 26px;
  display: block;
  margin: auto;
}
.one .left .shell p {
  font-size: 12px;
  text-align: center;
}
.one .left_text {
  width: 260px;
  height: 100%;
  position: absolute;
  background: #f9f9f9;
  top: 0;
  left: 60px;
  padding: 10px 0 0 20px;
}
.one .left_text .el-button {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  float: left;
}
.one .left_text .el-button:nth-child(2) {
  clear: both;
}
.one .left_text .left_text_shell {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.one .left_text .left_text_shell .text_left {
  width: 30%;
  float: left;
  margin-right: 4%;
  font-size: 14px;
  align-items: center;
}
.one .left_text .left_text_shell .text_right {
  width: 66%;
  align-items: center;
}
.one .left_text .left_text_shell .text_right .el-slider,
.one .left_text .left_text_shell .text_right .el-input-number {
  width: 84%;
}
.one .left_img {
  width: 260px;
  height: 100%;
  position: absolute;
  background: #f9f9f9;
  top: 0;
  left: 60px;
  padding-top: 10px;
}
.one .left_img .title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 2%;
  color: #409eff;
  clear: both;
}
.one .left_img .left_img_top {
  padding-left: 20px;
}
.one .left_img .left_img_top .el-button {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  float: left;
}
.one .left_img .left_img_top .el-button:nth-child(2) {
  clear: both;
}
.one .left_img .left_img_bottom_1 {
  padding-left: 20px;
}
.one .left_img .left_img_bottom_1 .el-button {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  float: left;
}
.one .left_img .left_img_bottom_1 .upload-btn .el-upload {
  width: 107px;
  height: 43px !important;
  border: none !important;
}
.one .left_img .left_img_bottom_1 .upload-btn .el-upload button {
  width: 100%;
}
.one .left_img .left_img_bottom_2 {
  padding-left: 20px;
}
.one .left_img .left_img_bottom_2 .el-button {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  float: left;
}
.one .left_img .left_img_bottom_2 .upload-btn .el-upload {
  width: 107px;
  height: 43px !important;
  border: none !important;
}
.one .left_img .left_img_bottom_2 .upload-btn .el-upload button {
  width: 100%;
}
.one .left_img .left_img_bottom_2 .left_text_shell {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.one .left_img .left_img_bottom_2 .left_text_shell .text_left {
  float: left;
  font-size: 14px;
  align-items: center;
}
.one .left_img .left_img_bottom_2 .left_text_shell .text_right {
  margin-left: 10px;
  align-items: center;
}
.one .left_img .left_img_bottom_2 .left_text_shell .text_right .el-slider,
.one .left_img .left_img_bottom_2 .left_text_shell .text_right .el-input-number {
  width: 84%;
}
.one .dcz {
  position: fixed;
  bottom: 20px;
  right: 40px;
  margin-left: -78px;
}
.one .right_sz {
  width: 700px;
  position: absolute;
  background: #f9f9f9;
  top: 0;
  left: 50%;
  margin-left: -400px;
  padding: 10px 20px 0 20px;
  border-radius: 0 0 8px 8px;
}
.one .right_sz .el-form-item {
  width: 28%;
  float: left;
  margin-bottom: 10px;
}
.one .right_sz .wh {
  width: 22%;
  float: left;
}
.tsClass1 {
  border-left: 4px solid #409eff;
  width: 46px !important;
}
