






























































































































































































































































































































































































































































































































































































































































































































































































































































































.one {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ebebeb;
  .canvas-container {
    position: absolute !important;
    top: 10%;
    left: 40%;
    background: #fff;
    border-radius: 8px;
  }
  #imageEffectCanvas {
    border-radius: 8px;
  }
  .left {
    width: 60px;
    height: 100%;
    overflow: hidden;
    background: #f0f3f7;
    .logo {
      width: 36px;
      display: block;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .shell {
      width: 50px;
      margin-bottom: 20px;
      cursor: pointer;
      padding-left: 4px;
      img {
        width: 26px;
        display: block;
        margin: auto;
      }
      p {
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .left_text {
    width: 260px;
    height: 100%;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 60px;
    padding: 10px 0 0 20px;
    .el-button {
      margin-left: 10px !important;
      margin-bottom: 10px !important;
      float: left;
    }
    .el-button:nth-child(2) {
      clear: both;
    }
    .left_text_shell {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      .text_left {
        width: 30%;
        float: left;
        margin-right: 4%;
        font-size: 14px;
        align-items: center;
      }
      .text_right {
        width: 66%;
        align-items: center;
        .el-slider,
        .el-input-number {
          width: 84%;
        }
      }
    }
  }
  .left_img {
    width: 260px;
    height: 100%;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 60px;
    padding-top: 10px;
    .title {
      text-align: center;
      font-size: 16px;
      margin-bottom: 2%;
      color: #409eff;
      clear: both;
    }
    .left_img_top {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .el-button:nth-child(2) {
        clear: both;
      }
      // .left_text_shell {
      //   width: 100%;
      //   display: flex;
      //   align-items: center;
      //   padding-left: 10px;
      //   .text_left {
      //     width: 29%;
      //     float: left;
      //     font-size: 14px;
      //     align-items: center;
      //   }
      //   .text_right {
      //     width: 66%;
      //     align-items: center;
      //     .el-slider,
      //     .el-input-number {
      //       width: 84%;
      //     }
      //   }
      // }
    }
    .left_img_bottom_1 {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .upload-btn {
        .el-upload {
          width: 107px;
          height: 43px !important;
          border: none !important;
          button {
            width: 100%;
          }
        }
      }
      // height: 30%;
      // margin-bottom: 4%;
      // overflow-y: scroll;
      // .bimg {
      //   width: 20%;
      //   height: 50px;
      //   margin-left: 4%;
      //   margin-bottom: 10px;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   transition: all 0.5s;
      //   &:hover {
      //     transform: scale(1.05);
      //   }
      // }
    }
    .left_img_bottom_2 {
      padding-left: 20px;
      .el-button {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
        float: left;
      }
      .upload-btn {
        .el-upload {
          width: 107px;
          height: 43px !important;
          border: none !important;
          button {
            width: 100%;
          }
        }
      }
      .left_text_shell {
        display: flex;
        align-items: center;
        padding-left: 10px;
        .text_left {
          float: left;
          font-size: 14px;
          align-items: center;
        }
        .text_right {
          margin-left: 10px;
          align-items: center;
          .el-slider,
          .el-input-number {
            width: 84%;
          }
        }
      }
      // height: 49%;
      // overflow-y: scroll;
      // .bimg {
      //   width: 44%;
      //   height: 180px;
      //   margin-left: 4%;
      //   margin-bottom: 10px;
      //   border-radius: 4px;
      //   cursor: pointer;
      //   transition: all 0.5s;
      //   &:hover {
      //     transform: scale(1.05);
      //   }
      // }
    }
  }
  .dcz {
    position: fixed;
    bottom: 20px;
    right: 40px;
    margin-left: -78px;
  }
  .right_sz {
    width: 700px;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    left: 50%;
    margin-left: -400px;
    padding: 10px 20px 0 20px;
    border-radius: 0 0 8px 8px;
    .el-form-item {
      width: 28%;
      float: left;
      margin-bottom: 10px;
    }
    .wh {
      width: 22%;
      float: left;
    }
  }
}
.tsClass1 {
  border-left: 4px solid #409eff;
  width: 46px !important;
}
